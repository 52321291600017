<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            readonly
          />
        </b-col>
        <b-col md="4">
          <!-- date  -->
          <g-picker
           :value.sync="selectedItem.transactionDate"
           :disabled="!currentBranch.setDefaultDate"
           label-text="date"/>
        </b-col>
        <!-- employee  -->
        <b-col md="4" >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="employee"
              :options="employees"
              rules="required"
              label="arabicName"
              label-text="employee"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row> -->
        <b-card border-variant="primary">
            <b-row class="px-2">
               <b-col md="4" >
              <label style="font-size: 14px;margin-bottom: 12px;" for="mainUnit">
                {{ $t('expense') }}
              </label>
                 <b-form-group>
                  <!-- main unit  -->
                     <g-field
                    :value.sync="selectedItem.expenseId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    :options="expenses"
                    label="arabicName"
                    @change="addExpenses(selectedItem.expenseId);"
                  />
                 </b-form-group>
               </b-col>
               <!-- <b-col cols="12" class="d-flex justify-content-end mb-50">
                <b-button
                  @click="
                    () => {
                      addExpenses(selectedItem.expenseId);
                    }
                  "
                  variant="primary"
                >
                  {{ $t('New Expense') }}
                </b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col cols="12" class="medium-window">
                <b-table
                  ref="b-table"
                  :items="selectedItem.expenses"
                  :fields="expensesTableColumns"
                  perPage="25"
                  small
                  show-empty
                  hover
                  stickyColumn
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  :empty-text="$t('noMatchingRecordsFound')"
                >
                <template #head(actions)>
                  <span></span>
                </template>
                <template #cell(value)="{ item }">
                  <g-field
                  class="mb-0 m-auto text-center"
                    :value.sync="item.value"
                    type="number"
                    rules="required"
                    :shortDesc="true"
                    size="sm"
                    :style="{ width: '200px' }"
                  />
                </template>
                <template #cell(notes)="{ item }">
                  <g-field
                  class="mb-0 m-auto text-center"
                    :value.sync="item.notes"
                    :shortDesc="true"
                    size="sm"
                  />
                </template>
                <template #cell(actions)="{ item }">
                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.bottom="$t('delete')"
                        variant="flat-danger"
                        size="sm"
                        @click="removeServiceFromTable(item)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          stroke="red"
                          class="mx-0 clickable danger"
                          :id="`invoice-row-${item.id}-delete-icon`"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
      <!-- </b-row> -->
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
        <b-row>
          <b-col cols="12">
          <hr />
        </b-col>
        </b-row>
      <b-row>
      <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" type="submit" data-action-type="save" variant="primary" v-permission="$route.meta.permission">
            {{ $t('save') }}
          </b-button>
        </b-col>
    </b-row>
      <!-- </b-row> -->
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';

export default {
  props: ['id'],
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      sortBy: 'id',
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      isSortDirDesc: false,
      sortDirection: 'asc',
      selectedItem: {
        transactionDate: '',
        expenses: []
      },
      expenses: [],
      employees: [],
      transactionDate: this.today,
    };
  },
  computed: {
    expensesTableColumns() {
      return [
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'notes',
          label: this.$t('notes'),
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false
        },
      ];
    },
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    this.loadObj();
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    addExpenses(id) {
      let errorMsg = '';
      if (this.selectedItem.expenses.find((v) => v.expenseId === id)) errorMsg = this.$t('canNotRepeatTheSameExpenses');
      if (errorMsg) {
        this.doneAlert({
          text: errorMsg,
          type: 'error',
        });
      } else {
        const res = this.expenses.find((v) => v.id === this.selectedItem.expenseId);
        this.expenseData = res
        this.expenseData.expenseId = res.id;
        this.expenseData.settlementId = this.id ? this.id : 0;
        this.selectedItem.expenses.push({ ...this.expenseData });
      }
      return false;
    },
    loadObj() {
      this.getEmployees();
      this.getBanks();
    },
    getEmployees() {
        this.get({ url: 'employees' }).then((data) => {
          this.employees = data;
        })
        this.get({ url: 'expenses' }).then((data) => {
          this.expenses = data;
        })
    },
    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
    },
    getData() {
        this.get({
          url: 'FinancialCustodySettlements',
          id: this.$route.params.id,
        }).then((data) => {
          data.expenses.forEach((item) => {
            const expensesName = this.expenses.find(
            (val) => val.id === item.expenseId
            ) || {
              arabicName: '',
              englishName: '',
            };
            item.arabicName = expensesName.arabicName;
          });
          this.selectedItem = data;
        })
    },
    save(type) {
      this.selectedItem.branchId = this.branchId;
        if (this.id > 0) {
          this.update({
            url: 'FinancialCustodySettlements',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('updatedSuccessfully') });
              if (type === 'saveAndPrint') this.print(this.id);
              if (type === 'cancel') {
                window.location.reload();
              } else {
                this.$router.push({ name: 'custodySettlement' });
              }
            });
        } else {
          this.create({
            url: 'FinancialCustodySettlements',
            data: this.selectedItem,
          })
            .then((data) => {
              this.doneAlert({
                text: this.$t('savedSuccessfullyWithCode', {
                  code: data.code,
                }),
              });
              if (type === 'saveAndPrint') this.print(data.id);
              this.$router.push({ name: 'custodySettlement' });
            });
        }
    }
  },
};
</script>

<style></style>
